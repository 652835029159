export default () => ({
    stepData: Array.from({ length: 16 }, (_, i) => ({
        step: i + 1,
        lMeasurement: 0,
        existingSetpoint: 0,
        newSetpoint: null
    })),
    isHorizontal: false,

    init() {
        // Initialize any necessary state
    },

    calculateSetpoints() {
        const measurements = this.stepData
            .filter(step => !isNaN(parseFloat(step.existingSetpoint)) && !isNaN(parseFloat(step.lMeasurement)))
            .map(step => ({
                setpoint: parseFloat(step.existingSetpoint),
                l_star: parseFloat(step.lMeasurement)
            }));

        if (measurements.length < 2) {
            alert('Please enter at least 2 valid measurements');
            return;
        }

        const minLStar = Math.min(...measurements.map(m => m.l_star));
        const maxLStar = Math.max(...measurements.map(m => m.l_star));

        const data = {
            measurements,
            min_l_star: minLStar,
            max_l_star: maxLStar
        };

        fetch('/tuner/calculate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            const correctedSetpoints = data.corrected_setpoints;
            this.stepData.forEach((step, index) => {
                if (index < correctedSetpoints.length) {
                    step.newSetpoint = Math.round(correctedSetpoints[index]);
                }
            });
        })
        .catch(error => {
            console.error('Error calculating setpoints:', error);
            alert('Error calculating setpoints. Please check the console for details.');
        });
    },

    focusNextInput(currentInput, isLastColumn, isShiftTab = false) {
        const allInputs = Array.from(currentInput.closest('tbody').querySelectorAll('input[type=number]'));
        const currentIndex = allInputs.indexOf(currentInput);
        let nextIndex;
        
        if (isShiftTab) {
            if (this.isHorizontal) {
                nextIndex = currentIndex > 0 ? currentIndex - 1 : allInputs.length - 1;
            } else {
                const numRows = allInputs.length / 2;
                const currentColumn = currentIndex % 2;
                const currentRow = Math.floor(currentIndex / 2);
                
                if (currentRow === 0) {
                    if (currentColumn === 1) {
                        nextIndex = (numRows - 1) * 2;
                        allInputs[nextIndex]?.focus();
                    } else {
                        const tabButton = document.querySelector('button[x-data]');
                        tabButton?.focus();
                        return;
                    }
                } else {
                    nextIndex = currentIndex - 2;
                    allInputs[nextIndex]?.focus();
                }
                return;
            }
            allInputs[nextIndex]?.focus();
            return;
        }
        
        if (this.isHorizontal) {
            nextIndex = isLastColumn ? 
                (Math.floor(currentIndex / 2) * 2 + 2) % allInputs.length : 
                currentIndex + 1;
        } else {
            const numRows = allInputs.length / 2;
            const currentColumn = currentIndex % 2;
            const currentRow = Math.floor(currentIndex / 2);
            
            if (currentRow === numRows - 1 && currentColumn === 1) {
                document.querySelector('button.bg-black.dark\\:bg-white')?.focus();
                return;
            }
            
            if (currentRow === numRows - 1) {
                nextIndex = currentColumn === 0 ? 1 : 0;
            } else {
                nextIndex = currentIndex + 2;
            }
        }
        
        if (nextIndex >= allInputs.length) {
            document.querySelector('button.bg-black.dark\\:bg-white')?.focus();
            return;
        }
        
        allInputs[nextIndex]?.focus();
    }
}); 