export default () => ({
    calibrationImage: null,
    materialPhoto: null,
    results: null,
    hasCalibrationImage: false,
    hasMaterialPhoto: false,

    init() {
        // Initialize drag and drop functionality for both upload areas
        const dropZones = document.querySelectorAll('.border-dashed');
        dropZones.forEach(dropZone => {
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                dropZone.addEventListener(eventName, (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                });
            });

            dropZone.addEventListener('drop', (e) => {
                const file = e.dataTransfer.files[0];
                if (file && file.type.startsWith('image/')) {
                    // Determine which drop zone was used
                    if (dropZone.contains(document.getElementById('calibration-image'))) {
                        this.handleCalibrationImageFile(file);
                    } else if (dropZone.contains(document.getElementById('material-photo'))) {
                        this.handleMaterialPhotoFile(file);
                    }
                }
            });
        });
    },

    handleCalibrationImageUpload(event) {
        const file = event.target.files[0];
        if (file) {
            this.handleCalibrationImageFile(file);
        }
    },

    handleMaterialPhotoUpload(event) {
        const file = event.target.files[0];
        if (file) {
            this.handleMaterialPhotoFile(file);
        }
    },

    handleCalibrationImageFile(file) {
        if (file.size > 5 * 1024 * 1024) { // 5MB
            alert('File is too large. Maximum size is 5MB.');
            return;
        }

        this.calibrationImage = file;
        this.hasCalibrationImage = true;
        this.results = null; // Reset results when new image is uploaded
    },

    handleMaterialPhotoFile(file) {
        if (file.size > 5 * 1024 * 1024) { // 5MB
            alert('File is too large. Maximum size is 5MB.');
            return;
        }

        this.materialPhoto = file;
        this.hasMaterialPhoto = true;
        this.results = null; // Reset results when new image is uploaded
    },

    async analyze() {
        if (!this.calibrationImage || !this.materialPhoto) return;

        const formData = new FormData();
        formData.append('calibration_image', this.calibrationImage);
        formData.append('material_photo', this.materialPhoto);

        try {
            const response = await fetch('/image-tuner/tune', {
                method: 'POST',
                body: formData,
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                    'Accept': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Tuning failed');
            }

            const data = await response.json();
            this.results = data;
        } catch (error) {
            console.error('Error tuning images:', error);
            alert('Failed to tune images. Please try again.');
        }
    },

    async exportResults(format = 'csv') {
        if (!this.results) return;

        try {
            const response = await fetch(`/image-tuner/export/${format}`, {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(this.results)
            });

            if (!response.ok) {
                throw new Error('Export failed');
            }

            // Handle the export response
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `grayscale-tuning-results.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error exporting results:', error);
            alert('Failed to export results. Please try again.');
        }
    }
}); 