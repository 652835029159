import Alpine from 'alpinejs';

export default (initialParameters = [], initialLasers = []) => ({
    parameters: (initialParameters || []).map(param => ({
        ...param,
        can: param.can || { update: true, delete: true }
    })),
    lasers: (initialLasers || []).map(laser => ({
        ...laser,
        can: laser.can || { update: true, delete: true }
    })),
    expandedLasers: new Set(),
    showLaserForm: false,
    showParameterForm: false,
    editingLaser: null,
    editingParameter: null,
    laserForm: {
        name: '',
        make: '',
        model: '',
        min_setpoint: '',
        max_setpoint: '',
        notes: ''
    },
    parameterForm: {
        name: '',
        material: '',
        laser_id: '',
        dpi: 300,
        speed: '',
        frequency: '',
        power: '',
        notes: ''
    },

    init() {
        // Initialize any necessary state
        this.expandedLasers = new Set();
        this.resetLaserForm();
        this.resetParameterForm();
    },

    isExpanded(laserId) {
        return this.expandedLasers.has(laserId);
    },

    toggleParameters(laserId) {
        if (this.expandedLasers.has(laserId)) {
            this.expandedLasers.delete(laserId);
        } else {
            this.expandedLasers.add(laserId);
        }
    },

    addParameterForLaser(laserId) {
        this.resetParameterForm();
        this.parameterForm.laser_id = laserId;
        this.showParameterForm = true;
    },

    getRelatedParameters(laserId) {
        return this.parameters.filter(p => p.laser_id === laserId);
    },

    getLaserName(laserId) {
        const laser = this.lasers.find(l => l.id === laserId);
        return laser ? laser.name : '';
    },

    formatSetpoint(value) {
        if (value === null || value === '') return '';
        return typeof value === 'number' && value <= 1 ? `${(value * 100).toFixed(2)}%` : value;
    },

    resetLaserForm() {
        this.laserForm = {
            name: '',
            make: '',
            model: '',
            min_setpoint: '',
            max_setpoint: '',
            notes: ''
        };
        this.editingLaser = null;
    },

    resetParameterForm() {
        this.parameterForm = {
            name: '',
            material: '',
            laser_id: '',
            dpi: 300,
            speed: '',
            frequency: '',
            power: '',
            notes: ''
        };
        this.editingParameter = null;
    },

    editLaser(laser) {
        this.laserForm = { ...laser };
        this.editingLaser = laser;
        this.showLaserForm = true;
    },

    editParameter(parameter) {
        this.parameterForm = { ...parameter };
        this.editingParameter = parameter;
        this.showParameterForm = true;
    },

    async saveLaser() {
        try {
            const url = this.editingLaser 
                ? `/equipment/${this.editingLaser.id}`
                : '/equipment';
            
            const method = this.editingLaser ? 'PUT' : 'POST';
            
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                    'Accept': 'application/json'
                },
                body: JSON.stringify(this.laserForm)
            });

            if (response.status === 403) {
                throw new Error('You are not authorized to perform this action.');
            }

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message || 'Failed to save');
            }
            
            const savedLaser = await response.json();
            savedLaser.can = savedLaser.can || { update: true, delete: true };
            
            if (this.editingLaser) {
                this.lasers = this.lasers.map(l => 
                    l.id === savedLaser.id ? savedLaser : l
                );
            } else {
                this.lasers.unshift(savedLaser);
            }
            
            this.showLaserForm = false;
            this.resetLaserForm();
        } catch (error) {
            console.error('Error saving laser:', error);
            alert(error.message || 'Failed to save laser. Please try again.');
        }
    },

    async saveParameter() {
        try {
            const url = this.editingParameter 
                ? `/parameters/${this.editingParameter.id}`
                : '/parameters';
            
            const method = this.editingParameter ? 'PUT' : 'POST';
            
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                    'Accept': 'application/json'
                },
                body: JSON.stringify(this.parameterForm)
            });

            if (response.status === 403) {
                throw new Error('You are not authorized to perform this action.');
            }

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message || 'Failed to save');
            }
            
            const savedParameter = await response.json();
            savedParameter.can = savedParameter.can || { update: true, delete: true };
            
            if (this.editingParameter) {
                this.parameters = this.parameters.map(p => 
                    p.id === savedParameter.id ? savedParameter : p
                );
            } else {
                this.parameters.unshift(savedParameter);
            }
            
            this.showParameterForm = false;
            this.resetParameterForm();
        } catch (error) {
            console.error('Error saving parameter:', error);
            alert(error.message || 'Failed to save parameter. Please try again.');
        }
    },

    async deleteLaser(laser) {
        if (!confirm('Are you sure you want to delete this laser?')) return;
        
        try {
            const response = await fetch(`/equipment/${laser.id}`, {
                method: 'DELETE',
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 403) {
                throw new Error('You are not authorized to delete this laser.');
            }

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message || 'Failed to delete laser');
            }
            
            this.lasers = this.lasers.filter(l => l.id !== laser.id);
            this.expandedLasers.delete(laser.id);
        } catch (error) {
            console.error('Error deleting laser:', error);
            alert(error.message || 'Failed to delete laser. Please try again.');
        }
    },

    async deleteParameter(parameter) {
        if (!confirm('Are you sure you want to delete this parameter?')) return;
        
        try {
            const response = await fetch(`/parameters/${parameter.id}`, {
                method: 'DELETE',
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                    'Accept': 'application/json'
                }
            });

            if (response.status === 403) {
                throw new Error('You are not authorized to delete this parameter.');
            }

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.message || 'Failed to delete parameter');
            }
            
            this.parameters = this.parameters.filter(p => p.id !== parameter.id);
        } catch (error) {
            console.error('Error deleting parameter:', error);
            alert(error.message || 'Failed to delete parameter. Please try again.');
        }
    }
}); 