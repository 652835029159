import './bootstrap';
import Alpine from 'alpinejs';

// Import components
import calibrationImageGenerator from './components/calibration-image-generator.js';
import laserManagement from './components/laser-management.js';
import laserEquipment from './components/laser-equipment.js';
import laserParameters from './components/laser-parameters.js';
import grayscaleTuner from './components/grayscale-tuner.js';
import rangeDefinition from './components/range-definition.js';
import imageTuner from './components/image-tuner.js';

// Theme Management
const themeManager = {
    init() {
        this.setupThemeHandlers();
        this.initializeTheme();
    },

    setupThemeHandlers() {
        document.addEventListener('themeChanged', (evt) => {
            const button = document.querySelector('[data-current-theme]');
            const mode = evt.detail?.mode || button?.dataset?.currentTheme || 'auto';
            this.applyTheme(mode);
        });
    },

    initializeTheme() {
        const button = document.querySelector('[data-current-theme]');
        const initialTheme = button?.dataset?.currentTheme || 'auto';
        this.applyTheme(initialTheme);
        
        // Set up system theme change listener for auto mode
        if (initialTheme === 'auto') {
            this.setupSystemThemeListener();
        }
    },

    applyTheme(mode) {
        const html = document.documentElement;
        const button = document.querySelector('[data-current-theme]');
        
        // Update button state
        if (button) {
            button.dataset.currentTheme = mode;
        }
        
        // Remove existing theme classes
        html.classList.remove('light', 'dark', 'auto');
        html.classList.add(mode);
        
        // Update icons
        const icons = document.querySelectorAll('#theme-icons svg');
        icons.forEach(icon => {
            const isCurrentTheme = icon.classList.contains(`theme-${mode}`);
            icon.style.opacity = isCurrentTheme ? '1' : '0';
            icon.style.visibility = isCurrentTheme ? 'visible' : 'hidden';
        });
        
        // Apply theme based on mode
        if (mode === 'dark') {
            html.classList.add('dark');
            html.classList.remove('light');
        } else if (mode === 'light') {
            html.classList.add('light');
            html.classList.remove('dark');
        } else {
            // Auto mode - check system preference
            const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
            html.classList.toggle('dark', prefersDark);
            html.classList.toggle('light', !prefersDark);
        }
        
        // Update the text
        const themeButton = document.querySelector('[data-current-theme]');
        const themeText = themeButton?.querySelector('.menu-text');
        if (themeText) {
            themeText.textContent = mode.charAt(0).toUpperCase() + mode.slice(1) + ' Mode';
        }
    },

    setupSystemThemeListener() {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        mediaQuery.addEventListener('change', (e) => {
            const button = document.querySelector('[data-current-theme]');
            if (button?.dataset?.currentTheme === 'auto') {
                const html = document.documentElement;
                html.classList.toggle('dark', e.matches);
                html.classList.toggle('light', !e.matches);
            }
        });
    }
};

// Make Alpine available globally
window.Alpine = Alpine;

// Register components
Alpine.data('calibrationImageGenerator', calibrationImageGenerator);
Alpine.data('laserManagement', laserManagement);
Alpine.data('laserEquipment', laserEquipment);
Alpine.data('laserParameters', laserParameters);
Alpine.data('grayscaleTuner', grayscaleTuner);
Alpine.data('rangeDefinition', rangeDefinition);
Alpine.data('imageTuner', imageTuner);

// Initialize Alpine store with default values
Alpine.store('calibrationImage', {
    formData: {
        laser_id: '',
        parameter_id: '',
        sample_size: 10,
        dpi: 400,
        num_steps: 16,
        spacing: 0,
        max_width: 100,
        max_height: 100
    }
});

// Start Alpine and initialize theme manager
document.addEventListener('DOMContentLoaded', () => {
    Alpine.start();
    themeManager.init();
});
