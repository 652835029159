import { crudMixin } from './mixins/crud';

export default function laserEquipment(initialLasers) {
    return {
        ...crudMixin,
        items: initialLasers,
        
        getDefaultFormData() {
            return {
                name: '',
                make: '',
                model: '',
                min_setpoint: '',
                max_setpoint: '',
                notes: ''
            };
        },

        formatSetpoint(value) {
            if (value === null || value === '') return '';
            return typeof value === 'number' && value <= 1 ? `${(value * 100).toFixed(2)}%` : value;
        },

        saveLaser() {
            return this.saveItem('equipment');
        },

        deleteLaser(laser) {
            return this.deleteItem(laser, 'equipment');
        }
    };
} 