export default () => ({
    measurements: [
        { setpoint: 0, l_star: null },
        { setpoint: 50, l_star: null },
        { setpoint: 100, l_star: null },
        { setpoint: 150, l_star: null },
        { setpoint: 200, l_star: null },
        { setpoint: 250, l_star: null }
    ],
    range: null,

    init() {
        // Initialize any necessary state
    },

    addMeasurement() {
        this.measurements.push({ setpoint: 0, l_star: null });
    },

    calculateRange() {
        const validMeasurements = this.measurements.filter(m => 
            !isNaN(parseFloat(m.setpoint)) && !isNaN(parseFloat(m.l_star))
        );

        if (validMeasurements.length < 2) {
            alert('Please enter at least 2 valid measurements');
            return;
        }

        const data = {
            measurements: validMeasurements,
            min_l_star: Math.min(...validMeasurements.map(m => m.l_star)),
            max_l_star: Math.max(...validMeasurements.map(m => m.l_star))
        };

        fetch('/range-definition/calculate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                'Accept': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            this.range = data.range;
        })
        .catch(error => {
            console.error('Error calculating range:', error);
            alert('Error calculating range. Please check the console for details.');
        });
    }
}); 