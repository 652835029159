// Common functionality for CRUD operations
export const crudMixin = {
    showForm: false,
    formData: {},
    editingItem: null,

    resetForm() {
        this.formData = this.getDefaultFormData();
        this.editingItem = null;
    },

    editItem(item) {
        this.formData = { ...item };
        this.editingItem = item;
        this.showForm = true;
    },

    async saveItem(endpoint) {
        try {
            const url = this.editingItem 
                ? `/${endpoint}/${this.editingItem.id}`
                : `/${endpoint}`;
            
            const method = this.editingItem ? 'PUT' : 'POST';
            
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                    'Accept': 'application/json'
                },
                body: JSON.stringify(this.formData)
            });

            if (!response.ok) throw new Error('Failed to save');
            
            const savedItem = await response.json();
            
            if (this.editingItem) {
                this.items = this.items.map(item => 
                    item.id === savedItem.id ? savedItem : item
                );
            } else {
                this.items.unshift(savedItem);
            }
            
            this.showForm = false;
            this.resetForm();
        } catch (error) {
            console.error(`Error saving ${endpoint}:`, error);
            alert(`Failed to save ${endpoint}. Please try again.`);
        }
    },

    async deleteItem(item, endpoint) {
        if (!confirm('Are you sure you want to delete this item?')) return;
        
        try {
            const response = await fetch(`/${endpoint}/${item.id}`, {
                method: 'DELETE',
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                    'Accept': 'application/json'
                }
            });

            if (!response.ok) throw new Error('Failed to delete');
            
            this.items = this.items.filter(i => i.id !== item.id);
        } catch (error) {
            console.error(`Error deleting ${endpoint}:`, error);
            alert(`Failed to delete ${endpoint}. Please try again.`);
        }
    }
}; 