import { crudMixin } from './mixins/crud';

export default function laserParameters(initialParameters, initialLasers) {
    return {
        ...crudMixin,
        items: initialParameters,
        lasers: initialLasers,
        
        getDefaultFormData() {
            return {
                name: '',
                material: '',
                laser_id: '',
                dpi: 400,
                speed: '',
                frequency: '',
                power: '',
                notes: '',
                sample_size: 10,
                spacing: 0,
                max_width: 100,
                max_height: 100,
                num_steps: 16
            };
        },

        getLaserName(laserId) {
            const laser = this.lasers.find(l => l.id === laserId);
            return laser ? laser.name : '';
        },

        saveParameter() {
            return this.saveItem('parameters');
        },

        deleteParameter(parameter) {
            return this.deleteItem(parameter, 'parameters');
        }
    };
} 